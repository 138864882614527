<template>
<div>
        <div class="header-title">
            {{$t("Notification")}}
        </div>

        <b-card no-body id="content">
            <b-tabs card>
                <b-tab active title-link-class="tab">
                    <template v-slot:title>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32.675" height="23.353" viewBox="0 0 59.675 51.353">
                            <g id="Group_471" data-name="Group 471" transform="matrix(0.809, 0.588, -0.588, 0.809, -502.178, 939.406)">
                                <g id="Group_470" data-name="Group 470" transform="translate(-128.134 -1069.727)">
                                    <path id="Path_135" data-name="Path 135" d="M-52.906-1029.151a3.294,3.294,0,0,1-1.048.12c-3.427-.128-8.734-4.849-12.236-8.853-3.845-4.4-9.55-12.627-6.541-15.59a2.8,2.8,0,0,1,2.152-.761c3.428.128,8.734,4.848,12.236,8.853,3.845,4.4,9.55,12.626,6.54,15.59A2.6,2.6,0,0,1-52.906-1029.151Zm-.85-2.569Zm-17.04-19.77c-.093.323-.04,1.534,1.2,3.979a40.165,40.165,0,0,0,5.476,7.817c4.987,5.7,9.325,8.138,10.384,7.919.093-.323.04-1.534-1.2-3.979a40.147,40.147,0,0,0-5.476-7.818C-65.4-1049.273-69.738-1051.709-70.8-1051.49Zm.021-.054Z" transform="translate(88.579 1054.237)" fill="#707070"/>
                                    <path id="Path_136" data-name="Path 136" d="M-120.216-1011.085a5.927,5.927,0,0,1-5.907-1.744,6.6,6.6,0,0,1-1.332-7.613l14.59-27.783,2.435,1.279-14.588,27.778a3.881,3.881,0,0,0,.865,4.422,3.147,3.147,0,0,0,3.578.863l27.169-13.481,1.222,2.464-27.179,13.486A5.445,5.445,0,0,1-120.216-1011.085Z" transform="translate(128.134 1049.878)" fill="#707070"/>
                                    <rect id="Rectangle_192" data-name="Rectangle 192" width="3.191" height="10.074" rx="1.596" transform="translate(25.254 32.109) rotate(65.6)" fill="#707070"/>
                                    <rect id="Rectangle_193" data-name="Rectangle 193" width="3.191" height="6.02" rx="1.596" transform="matrix(-0.911, 0.413, -0.413, -0.911, 26.581, 35.045)" fill="#707070"/>
                                </g>
                                <rect id="Rectangle_194" data-name="Rectangle 194" width="2.366" height="7.59" rx="1.183" transform="translate(-97.835 -1075.603) rotate(22.832)" fill="#707070"/>
                                <rect id="Rectangle_195" data-name="Rectangle 195" width="2.366" height="10.942" rx="1.183" transform="translate(-83.891 -1071.347) rotate(54.323)" fill="#707070"/>
                                <rect id="Rectangle_196" data-name="Rectangle 196" width="2.366" height="8.307" rx="1.183" transform="matrix(0.024, 1, -1, 0.024, -83.223, -1056.057)" fill="#707070"/>
                            </g>
                        </svg>
                        <span class="tab-title">{{$t("Annoucement")}}</span>
                    </template>
                </b-tab>

                <b-tab>
                    <template v-slot:title>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="23.421" viewBox="0 0 448 512">
                                <path d="M439.39 362.29c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71zM67.53 368c21.22-27.97 44.42-74.33 44.53-159.42 0-.2-.06-.38-.06-.58 0-61.86 50.14-112 112-112s112 50.14 112 112c0 .2-.06.38-.06.58.11 85.1 23.31 131.46 44.53 159.42H67.53zM224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64z" fill="#707070"/>
                            </svg>
                        <span class="tab-title">{{$t("Inbox")}}</span>
                    </template>

                    <div class="d-flex justify-content-center mt-3" id="inbox-body">
                        <div class="inbox-container mb-3" v-for="row in rows" v-bind:key="row.id">
                            <div class="d-flex flex-row col-md-12">
                                <div class="col-md-7 inbox-title">{{ row.title }}</div>
                                <div class="col-md-4">{{ row.date }}</div>
                                <div class="dropdown-btn">
                                    <button v-on:click="toggleDiv()" class="icon-btn">
                                        <span v-if="display_div">
                                            <i class="fa fa-chevron-up" aria-hidden="true"></i>
                                        </span>
                                        <span v-else>
                                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div v-if="display_div" class="col-md-11 text-gray">
                                {{ row.details }}
                            </div>
                        </div>
                    </div>
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>

<script>
    export default ({
        name: 'Notification',
        data() {
            return{
                display_div: false,
                rows: [
                    {   id: 1, 
                        title: 'Login attempted from new IP', 
                        details: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the', 
                        date: '2021-09-13 19:35:20'
                    }
                ],
            }
        },
        methods: {
            toggleDiv: function() {
                this.display_div = !this.display_div;
            }
        }
    })
</script>

<style>
@import '../css/styles.css';

.inbox-container {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    width: 600px;
    min-height: 45px;
    place-content: center;
    height: fit-content;
    background-color: #2b2e44;
    border-radius: 15px;
    color: white;
    padding: 15px;
    font-size: 14px;
    position: relative;
}

.inbox-title {
    padding: 0px;
    font-size: 16px;
}

.dropdown-btn {
    position: absolute;
    right: 0;
}
</style>